.basic-dialog {
  h1 {
    margin-bottom: var(--space-2);
  }
  p {
    font-size: var(--default-font-size);
    color: var(--font-basic);
    line-height: var(--line-height-6);
  }
  .close-btn {
    position: absolute;
    top: var(--space-2);
    right: var(--space-2);
  }
  .textarea {
    height: 130px;
  }

  .form-label {
    min-width: 160px;
  }

  .error-box {
    width: 100%;
    padding: 16px 0;
    text-align: center;
    background: #fbfbfb;
    margin-top: 4px;
    p {
      color: #f12828;
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .error-text {
    padding-left: 160px;
    text-align: left;
    color: #f12828;
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
    margin-top: 0;
  }
}

.state-dialog {
  .rt-Flex {
    p {
      flex: 0 0 20%;
    }
  }
  .rt-r-jc-center {
    padding: 0 var(--space-8);
  }
  .rt-Separator {
    background-color: var(--gray-9);
  }
  .preview-contents {
    .rt-Heading {
      display: none;
    }
    .box {
      padding: 0;
      margin-bottom: 0;
    }
  }
  .dialog-select-box {
    flex: 1;
  }
}
