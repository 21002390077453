header {
  width: 100%;
  background-color: var(--bg-white);
  border-bottom: 1px solid var(--border-light);
  // position: fixed;
  // top: 0;
  // left: 0;
  z-index: 90;
  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(var(--space-1) + 2px) var(--space-4);
    width: 200px;
    height: 64px;
  }
  nav {
    display: flex;
    align-items: center;
    gap: var(--space-5);
    padding-right: var(--space-5);
    margin-left: auto;
  }
  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      color: var(--font-basic);
    }
  }
  .icon {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    font-size: 0;
  }
  .avatar {
    width: 34px;
    height: 34px;
    background-size: 34px 34px;
    &.master {
      background-image: url('../../assets/images/icon/icon-avatar-master.svg');
    }
    &.admin {
      background-image: url('../../assets/images/icon/icon-avatar-admin.svg');
    }
    &.user {
      background-image: url('../../assets/images/icon/icon-avatar-user.svg');
    }
  }

  .logout {
    background-image: url('../../assets/images/icon/icon-logout.svg');
  }
  .change-password {
    background-image: url('../../assets/images/icon/icon-change-password.svg');
  }
}
