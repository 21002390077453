@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  /* --ckeditor5-preview-sidebar-width: 270px; */
  --ckeditor5-preview-height: 380px;
  font-family: 'Lato';
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .editor-container_document-editor {
    &:not(:has(.read-only)) {
      border: 1px solid var(--border-light);
    }

    .editor-container__toolbar {
      display: flex;
      position: relative;
      box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);

      .ck.ck-toolbar {
        flex-grow: 1;
        width: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
      }
    }

    .editor-container__menu-bar {
      .ck.ck-menu-bar {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
      }
    }

    .editor-container__minimap-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      background: hsl(0, 0%, 100%);

      .editor-container__editor-wrapper {
        width: 100%;
        height: var(--ckeditor5-preview-height);
        display: flex;
        background: var(--bg-white);
        &.read-only {
          height: 100%;
        }

        .editor-container__editor {
          overflow: auto;
          width: 100%;
        }

        .ck-content,
        .ck.ck-editor__editable {
          width: 100%;
          height: 100%;
          color: var(--font-basic);
          font-family: 'Lato';
          line-height: 1.6;
          word-break: break-all;
        }

        .ck.ck-editor__editable {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          // padding: var(--space-6);
          padding: 0;
          flex: 1 1 auto;
          p {
            margin: 0;
            & + p {
              margin-top: var(--space-4);
            }
          }
        }
      }
    }
  }

  .editor-container__sidebar {
    min-width: var(--ckeditor5-preview-sidebar-width);
    max-width: var(--ckeditor5-preview-sidebar-width);
    margin-top: 28px;
    margin-left: 10px;
    margin-right: 10px;

    .editor-container__sidebar_minimap {
      max-width: calc(var(--ckeditor5-preview-sidebar-width) / 2);
      min-width: calc(var(--ckeditor5-preview-sidebar-width) / 2);
      max-height: 100%;
      margin: 0;
      flex: 0 0 auto;
      border-left: 1px solid var(--ck-color-toolbar-border);
      position: relative;
      overflow: hidden;
    }
  }
}
