.radix-themes {
  .rt-BaseCheckboxRoot {
    margin-right: var(--space-2);
    &:disabled {
      &::before {
        background-color: var(--bg-disabled);
      }
      .rt-BaseCheckboxIndicator {
        path {
          fill: var(--border-light);
        }
      }
    }
  }
  .rt-BaseCheckboxIndicator {
    path {
      fill: var(--border-white);
    }
  }
}
