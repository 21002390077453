.radix-themes {
    .rt-BaseDialogContent {
        position: relative;
        padding: var(--space-13) var(--space-6) var(--space-6);
        text-align: center;
        &.state-dialog {
            padding: var(--space-6);
            text-align: left;
        }
    }
}
