.post-title {
  padding-block: var(--space-6);
  border-block-start: 2px solid var(--gray-12);
  h4 {
    padding-inline: var(--space-4);
  }
}

.post-info {
  width: 100%;
  height: var(--space-10);
  padding-block: calc(var(--space-1) + 1px);
  padding-inline: var(--space-3);
  background-color: var(--bg-light);
  color: var(--font-basic);
}

.post-box {
  .post-image {
    margin-block-end: var(--space-6);
    text-align: center;
  }
}

.post-link {
  > div {
    min-width: 130px;
  }
  .post-outline {
    width: 100%;
    min-height: 64px;
    border: 1px solid var(--border-light);
    padding-block: calc(var(--space-4) + 1px);
    padding-inline: var(--space-7);
  }
  .icon-btn {
    aspect-ratio: 1 / 1;
    box-sizing: border-box;
    height: var(--base-button-height);
    background-color: transparent;
    color: var(--font-basic);
    pointer-events: none;
  }
}

.post-comment {
  padding: var(--space-4);
  border: 1px solid var(--border-light);
  .post-comment-head {
    min-height: var(--space-8);
    background: var(--bg-light);
    .rt-IconButton {
      color: var(--gray-12);
      padding: 5px var(--space-2);
      svg {
        margin-right: 4px;
      }
      span {
        line-height: unset;
      }
      & + button {
        margin-left: var(--space-1);
      }
      &.active {
        color: var(--color-info);
      }
    }
  }
  .post-comment-body:not(:has(.textarea)) {
    padding: var(--space-3);
  }
}
