.radix-themes {
    --container-4: 100%;
    .rt-Container {
        width: 100%;
        min-width: 1400px;
        .rt-ContainerInner {
            min-width: 1400px;
        }
    }
}

.login-wrap {
    .rt-Container {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
