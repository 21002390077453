.sort-by-local {
    width: 100%;

    button {
        flex-grow: 1;
        padding: 0;
        background-color: var(--bg-white);
        border: 1px solid var(--border-light);
        color: var(--font-basic);

        &:hover {
            background-color: var(--bg-light);
            border: 1px solid var(--border-darken);
            color: var(--font-darken);
        }

        &.active {
            background-color: var(--bg-primary);
            color: var(--font-white);
            border: none;
        }
    }
}
