.password-wrap {
  width: 100%;
  height: calc(100vh - 40px);
  max-width: 800px;
  margin: 0 auto;
  .email-wrap {
    position: relative;
    border-top: 1px solid var(--border-darken);
    border-bottom: 1px solid var(--border-light);
    .label {
      width: 100px;
    }
    .error-input {
      border-color: var(--color-error);
    }
    .state-msg {
      position: absolute;
      left: 106px;
      bottom: var(--space-8);
      img {
        margin-top: 2px;
      }
    }
  }
}
