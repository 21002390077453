.radix-themes {
  .rt-TextFieldRoot {
    flex: 1 1;
    position: relative;
    padding: 0;
    border: 1px solid var(--border-light);
    background-image: inherit;
    box-shadow: none;
    color: var(--font-basic);
    &.rt-r-size-2 {
      --text-field-height: var(--space-8);
    }
    &.rt-r-size-3 {
      --text-field-height: var(--space-12);
    }
    &:hover:not(:disabled, :read-only) {
      border-color: var(--border-darken);
    }
    &:focus:not(:disabled, :read-only) {
      border-color: var(--border-focus);
    }
    &.date-pickers {
      min-width: 160px;
      flex: 0;
    }
  }
  .rt-TextFieldInput {
    position: relative;
    padding-left: var(--space-3);
    padding-right: calc(var(--space-3) - 2px);
    padding-bottom: 0;
    font-size: var(--default-font-size);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-6);
    text-indent: 0;
    &:disabled,
    &:read-only {
      -webkit-text-fill-color: inherit;
      // background-color: var(--bg-disabled);
      background-color: var(--bg-white);
      pointer-events: none;
    }
    &::placeholder {
      padding: 0;
      color: var(--font-light);
    }
  }
  .rt-TextFieldSlot {
    padding: 0;
    button {
      margin-right: var(--space-3);
      width: 24px;
      height: 24px;
      background-image: url('../../../images/icon/icon-reset.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      font-size: 0;
    }
    .icon-error {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: var(--space-3);
      width: 24px;
      height: 24px;
      color: var(--font-error);
      svg {
        padding: calc(var(--space-1) - 2px);
      }
    }
  }

  fieldset,
  .flag-error {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100%;
    border: 0;
  }
  .flag-error {
    .rt-TextFieldSlot {
      button {
        margin-right: var(--space-1);
      }
    }
    .caption {
      margin-top: var(--space-1);
      width: 100%;
      color: var(--font-error);
      font-size: var(--font-size-1);
      font-weight: var(--font-weight-regular);
      line-height: calc(var(--font-size-1) * 1.5); // 18px
    }
  }

  .button-search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: var(--space-3);
    padding: var(--space-1);
    width: 36px;
    height: 36px;
  }

  .date-pickers {
    &.empty {
      .rt-TextFieldInput {
        color: var(--font-light);
      }
    }
    .rt-TextFieldInput {
      background: var(--bg-white) url('../../../images/icon/icon-calander.svg') no-repeat center right var(--space-3) / 24px 24px;
      &::-webkit-clear-button,
      &::-webkit-inner-spin-button {
        display: none;
      }
      &::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        color: transparent;
        cursor: pointer;
      }
      &:disabled,
      &:read-only {
        // background-color: var(--bg-disabled);
        background-color: var(--bg-white);
        &::before {
          background-color: var(--bg-disabled);
        }
      }
      &::before {
        // 주석 해제시 readOnly할경우 value 값이 안보입니다
        // content: attr(placeholder);
        position: absolute;
        top: 0;
        left: 0;
        padding-left: var(--space-3);
        padding-right: calc(var(--space-3) - 2px);
        width: calc(100% - var(--space-9));
        height: 100%;
        background-color: var(--bg-white);
        color: var(--font-light);
      }
      &:focus::before,
      &:valid::before {
        display: none;
      }
    }
  }

  .password {
    .rt-TextFieldSlot {
      button {
        background-image: url('../../../images/icon/icon-hidden.svg');
        background-size: contain;
        &.active {
          background-image: url('../../../images/icon/icon-view.svg');
        }
      }
    }
  }

  .file-upload {
    .rt-TextFieldRoot {
      display: none;
    }
    label {
      padding: var(--space-1) var(--space-4);
      border: 1px solid var(--border-darken);
      color: var(--font-darken);
      font-size: var(--font-size-2);
      font-weight: var(--font-weight-regular);
      line-height: calc(var(--default-font-size) * 1.375); // 22px
    }
    p {
      margin-left: var(--space-5);
      color: var(--font-basic);
      font-size: var(--default-font-size);
      font-weight: var(--font-weight-regular);
      line-height: var(--line-height-6);
    }
    button {
      margin-left: var(--space-3);
      width: 32px;
      height: 32px;
      font-size: 0;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  .input-upload {
    .rt-TextFieldRoot {
      width: auto;
      height: auto;
      //background-color: var(--bg-white);
      background-color: #fff;
    }
    .rt-TextFieldInput {
      border: 0;
      //background-color: var(--bg-white);
      background-color: #fff;
      --text-field-padding: 0;
      &::file-selector-button {
        width: 84px;
        height: 32px;
        margin-right: 20px;
        background-color: transparent;
        border: 1px solid var(--gray-12);
        border-radius: 0;
        color: var(--font-darken);
        font-size: var(--font-size-2);
        // cursor: pointer;
      }
    }
  }

  .form-type-count {
    .date-pickers {
      flex: 1;
    }
  }

  .attached-input-box {
    border-radius: var(--space-1);
    overflow: hidden;
    border: 1px solid var(--border-light);
    .rt-TextFieldRoot {
      border: none;
      pointer-events: none;
      .rt-TextFieldInput {
        padding: 0 var(--space-4);
        &::placeholder {
          color: var(--font-basic);
        }
      }
      &.video {
        pointer-events: all;
        .rt-TextFieldInput::placeholder {
          text-decoration: underline;
        }
      }
    }
  }
}
