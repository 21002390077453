.table-write {
    .file-upload {
        display: flex;
        align-items: center;
        gap: var(--space-2);
        height: var(--space-12);
    }
    .file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--space-10);
    }
}
