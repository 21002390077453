.pagination {
    .rt-IconButton,
    .rt-BaseButton {
        background-color: transparent;
        color: var(--font-basic);
        cursor: pointer;
        &.rt-variant-text {
            margin: 0;
        }
        &.currentPage {
            color: var(--font-color-white);
            background-color: var(--accent-9);
            &:hover {
                color: var(--font-color-white);
            }
        }
    }
    .pages {
        display: flex;
        align-items: center;
        margin-inline: var(--space-3);
        .rt-IconButton {
            font-size: var(--font-size-2);
        }
        .rt-variant-text {
            &:hover:not(.currentPage) {
                color: var(--color-info);
            }
        }
        .rt-BaseButton {
            &:disabled {
                background: transparent;
                border: none;
            }
        }
    }
}
