.radix-themes {
  .rt-TextAreaRoot {
    box-shadow: unset;
    &.rt-r-size-3 {
      min-height: 100px;
    }
  }
  .rt-TextAreaInput {
    padding: 0;
    font-size: var(--default-font-size);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-6);
    color: var(--font-basic);
    &::placeholder {
      color: var(--font-light);
    }
    &:read-only,
    &:disabled {
      background-color: var(--bg-white);
      cursor: var(--cursor-disabled);
      &:focus,
      &:focus-visible,
      &:active {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
  }
  .textarea {
    position: relative;
    height: 100px;
    padding: var(--space-2);
    border: 1px solid var(--border-light);
    &:hover:not(.disabled, :read-only) {
      border-color: var(--border-darken);
    }
    &.focus:not(.disabled, :read-only) {
      border-color: var(--border-focus);
    }
    &.disabled {
      border-color: var(--border-light);
      background-color: var(--bg-white);
    }
    &.height-md {
      height: 140px;
    }
    &:not(:has(.count)) {
      .rt-r-size-3 {
        height: 100%;
      }
    }
    .count {
      color: var(--font-light);
      font-size: var(--font-size-1);
      font-weight: var(--font-weight-medium);
      line-height: calc(var(--font-size-1) * 1.5); // 18px
      text-align: right;
    }
    @at-root .box-wide {
      .textarea {
        flex: 1;
      }
    }
  }
}
