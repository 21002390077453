.radix-themes {
  .rt-SwitchRoot {
    width: 56px;
    height: 28px;
    border-radius: 14px;
    background-color: var(--accent-track);  
    &::before {
      width: 56px;
      height: 28px;
      border-radius: 14px;
      box-shadow: inherit;
      background-image: none !important;
    }  
    .rt-SwitchThumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      box-shadow: inherit;
      transform: translateX(4px);
      &[data-state="checked"] {
        transform: translateX(30px);
      }
    }
    &[data-state="unchecked"] {
      background-color: var(--bg-light);
    }
    &:disabled {
      &::before {
        background-color: var(--bg-disabled);
      }
      .rt-SwitchThumb {
        background-color: var(--switch-disabled-thumb);
      }
    }
  }
}
