.login-wrap {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    height: calc(100vh - 40px);

    .login-pannel {
        min-width: 454px;
        height: 100%;

        .logo {
            width: 138px;
            height: 48px;
        }

        .login-text {
            font-size: var(--font-size-2);
            font-weight: var(--font-weight-regular);
            line-height: calc(var(--line-height-2) + 2px);
        }

        .login-box {
            height: 100%;
            border-top: 1px solid var(--border-darken);

            form {
                // height: 100%;

                .rt-Flex {
                    &:first-child {
                        margin-bottom: calc(var(--space-3) + 2px);
                    }

                    label {
                        min-width: 100px;
                        color: var(--font-color-darken);
                    }

                    .rt-TextFieldInput {
                        height: inherit;
                        padding-bottom: 1px;
                    }
                }

                .error-msg {
                    width: 100%;
                    img {
                        margin-top: 2px;
                    }
                    span {
                        color: var(--font-error);
                    }
                }
            }
        }
    }

    .copyright {
        position: absolute;
        left: 50%;
        bottom: 28px;
        transform: translateX(-50%);
        color: var(--font-light);
    }
}
