.join-wrap {
    width: 100%;
    height: calc(100vh - 40px);
    max-width: 800px;
    margin: 0 auto;

    .request-wrap {
        border-top: 1px solid var(--border-darken);
        .request-bar {
            padding-left: calc(var(--space-2) + 3px);
            .rt-Text {
                width: 150px;
            }
            .email-bar {
                flex: 1;
                .error-state {
                    border-color: var(--color-error);
                }
            }
        }
        .state-msg {
            padding-left: 170px;
            &.region {
                padding-left: 0;
            }
        }
        .auth-bar {
            max-width: 182px;
        }
    }

    .term-wrap {
        .all-check {
            border-bottom: 1px solid var(--border-darken);
        }
        .term-box {
            border: 1px solid var(--border-light);
            background: var(--bg-light);
            padding: var(--space-4) var(--space-6) var(--space-8) var(--space-6);
        }
    }

    .welcome-wrap {
        border-top: 1px solid var(--border-darken);
        border-bottom: 1px solid var(--border-light);
        .welcome-title {
            font-size: var(--font-size-7);
            line-height: var(--line-height-8);
            font-weight: var(--font-weight-bold);
        }
    }
}
