.required {
  &-start {
    position: relative;
    &::before {
      content: "*";
      position: absolute;
      top: 50%;
      left: calc(var(--space-1) * -1);
      transform: translate(-100%, -50%);
      width: 8px;
      height: 8px;
      color: var(--color-error);
      line-height: 1;
    }
  }
  &-end {
    &::after {
      content: "*";
      display: inline-block;
      margin-left: 4px;
      color: var(--color-error);
    }
  }
}
