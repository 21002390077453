main {
  flex-grow: 1;
  padding: 0 var(--space-10) var(--space-10);
  min-height: calc(100vh - 65px);
  position: relative;
}

.bg-gray {
  background-color: var(--bg-light);
}
.bg-white {
  background-color: var(--bg-white);
}
