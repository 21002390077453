.box {
    margin-block-end: var(--space-4);
    padding-inline: var(--space-6);
    padding-block: var(--space-6);

    &.box-white {
        background-color: var(--bg-white);
    }

    &.box-gray {
        background-color: var(--bg-light);
    }

    &.box-outline {
        min-height: 130px;
        padding-inline: var(--space-2);
        padding-block: var(--space-2);
        background-color: var(--bg-white);
        border: 1px solid var(--border-light);
        margin-block-end: 0;
    }

    &.box-wide {
        width: 100%;
        padding-inline: 0;
        padding-block: 0;
        margin-block-end: 0;
    }
}
