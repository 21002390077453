.radix-themes {
    .rt-BaseRadioRoot {
        margin-right: calc(var(--space-1) + 2px);
        &.rt-r-size-3 {
            &::before {
                width: var(--space-6);
                height: var(--space-6);
            }
            &::after {
                width: var(--space-7);
                height: var(--space-7);
            }
        }
        &:checked,
        &[data-state='checked'] {
            &::before {
                background-color: var(--bg-white);
                border-color: var(--color-success);
                box-shadow: inset 0 0 0 1px var(--color-success);
            }
            &::after {
                background-color: var(--color-success);
            }
        }
        &:disabled,
        &[data-disabled] {
            &::before {
                background-color: var(--bg-disabled);
            }
        }
    }
}
