.board-search-box {
  width: 100%;
  margin-bottom: 10px;

  .rt-Box {
    .rt-SelectTrigger {
      min-width: 160px;
    }
    .rt-TextFieldInput {
      min-width: 252px;
    }
  }
  .rt-IconButton {
    &.rt-variant-text {
      --base-button-height: var(--space-9);
      margin-left: 4px;
    }
  }
}

.board-text-items {
  .rt-TableRoot {
    --table-cell-min-height: var(--space-12);
    --table-cell-padding: var(--space-2);
    border-inline: none;
    border-block-start: 1px solid var(--gray-12);

    .rt-TableHeader {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-bold);

      .rt-BaseCheckboxRoot {
        margin-right: 0;
      }
    }

    .rt-TableBody {
      font-size: var(--font-size-3);

      .rt-TableRow {
        color: var(--font-basic);
        &.row-notice {
          background-color: var(--bg-blue);
          color: var(--font-darken);
          font-weight: var(--font-weight-bold);
        }
      }

      .rt-TableCell {
        --table-cell-padding: var(--space-2);
        padding: var(--table-cell-padding);
        border-right: 1px solid var(--border-light);

        &:last-child {
          border-right: none;
        }

        a {
          display: block;
        }

        &.has-detail {
          cursor: pointer;
          text-decoration: underline;
          padding-inline: var(--space-4);
        }
      }

      .rt-Flex {
        .rt-BaseCheckboxRoot {
          margin-right: 0;
        }
      }
    }

    .rt-TableColumnHeaderCell {
      position: relative;
      --table-cell-padding: var(--space-2);
      padding: var(--table-cell-padding);

      &:not(:first-child)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 24px;
        background-color: var(--border-light);
        transform: translateY(-50%);
      }
    }
  }
}

.no-data {
  padding: 225px 0;
  border-top: 1px solid var(--border-darken);
}
