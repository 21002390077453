aside {
    position: relative;
    &.vertical {
        width: 200px;
        background-color: var(--color-primary);
        flex-shrink: 0;
        &.active {
            flex-shrink: 1;
            width: 0;
            .AccordionRoot {
                visibility: hidden;
            }
            .gnb {
                visibility: hidden;
            }
        }
        a {
            text-decoration-line: none;
        }
    }
    .AccordionItem {
        border-bottom: 1px solid #2d3a58;
    }
    .AccordionTrigger {
        width: 100%;
        padding: 12px 16px 12px 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--font-white);
        font-size: var(--default-font-size);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-6);
        cursor: pointer;
        .AccordionChevron {
            width: 24px;
            height: 24px;
            background-image: url('../../assets//images/icon/icon-arrow-down.svg');
            background-repeat: no-repeat;
            filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(48deg) brightness(102%) contrast(107%);
            path {
                display: none;
            }
        }
        &[data-state='open'] {
            color: var(--aside-info);
            background-color: var(--aside-trigger-active);
            .AccordionChevron {
                transform: rotate(180deg);
                filter: brightness(0) saturate(100%) invert(50%) sepia(19%) saturate(1991%) hue-rotate(177deg) brightness(99%) contrast(80%);
            }
        }
    }
    .AccordionContent {
        background-color: var(--aside-depth2-bg);
        overflow: hidden;
    }
    .AccordionContentText {
        padding: 8px;
        a {
            display: block;
            color: var(--font-white);
            padding: 9px 28px;
            font-weight: bold;
            opacity: 0.6;
            border-radius: 2px;
            &:hover,
            &.active {
                opacity: 1;
                background-color: var(--aside-info);
            }
        }
    }
    .folding-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: -28px;
        width: 28px;
        height: 54px;
        font-size: 0;
        border-radius: 0 0 4px 0;
        background-color: var(--bg-white);
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.08);
        &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background-image: url('../../assets/images/icon/icon-arrow-left.svg');
            background-repeat: no-repeat;
        }

        &:hover {
            &::before {
                filter: brightness(0) saturate(100%) invert(53%) sepia(65%) saturate(2774%) hue-rotate(195deg) brightness(94%) contrast(90%);
            }
        }
        &.active {
            background-color: var(--bg-primary);
            &::before {
                transform: rotate(180deg);
                filter: brightness(0) saturate(100%) invert(100%) sepia(20%) saturate(7500%) hue-rotate(189deg) brightness(125%)
                    contrast(101%);
            }
            &:hover {
                background-color: var(--blue-10) !important;
            }
        }
    }
    .gnb {
        a {
            display: block;
            padding: var(--space-3) var(--space-4) var(--space-3) var(--space-7);
            border-bottom: 1px solid #2d3a58;
            color: var(--font-white);
            font-size: var(--font-size-3);
            font-weight: var(--font-weight-bold);
            line-height: var(--line-height-6);
            text-decoration-line: none;
            &.active {
                color: var(--color-info);
            }
        }
    }
}
@media (max-width: 992px) {
    // aside {
    //     &.vertical {
    //       display: none;
    //     }
    // }
    .user-info {
        p {
            text-align: right;
        }
    }
}
